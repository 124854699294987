import $ from 'jquery';
/**
 * Enhance native scrolling element with slider functionality
 *
 * @param {HTMLElement} container
 * @param {Object} options
 */
export class Slider {
	get scrollLeftMax() {
		return this.container.scrollWidth - this.container.scrollLeft;
	}

	get sizeName() {
		return this.scrollLeftMax > 0 ? 'Width' : 'Height';
	}

	get positionName() {
		return this.scrollLeftMax > 0 ? 'Left' : 'Top';
	}

	get scroll() {
		return this.container[`scroll${this.sizeName}`];
	}

	get offset() {
		return this.container[`client${this.sizeName}`];
	}

	get position() {
		return this.container[`scroll${this.positionName}`];
	}

	get hasPrevious() {
		return this.position > 0;
	}

	get hasNext() {
		// add
		return this.position + 1 < this.scroll - this.offset;
	}

	constructor(container, {previous = null, next = null, clickable = null} = {}) {
		this.container = container;
		this.previous = previous;
		this.next = next;
		// per Klick weiternavigieren statt nur per Drag 40% Regel wie bei Photoswipe
		this.clickable = clickable;

		if (this.previous) this.previous.addEventListener('click', (e) => {
			this.slideTo('previous', e.target);
		});
		if (this.next) this.next.addEventListener('click', (e) => {
			this.slideTo('next', e.target);
		});

		this.update();
		this.container.addEventListener('scroll', this.update.bind(this), {passive: true});

		this.useMouse();
	}

	useMouse() {

		// detect if mouse is available, if not return
		// https://stackoverflow.com/a/9296961/270935
		if (!!('ontouchstart' in window)) return; // if touch only without mouse

		// https://codepen.io/thenutz/pen/VwYeYEE?editors=1010
		const slider = this.container;
		let isDown = false;
		let startX;
		let firstPosition;
		let threshold = 150;
		let scrollLeft;

		let is_safari = navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf("Chrome") === -1;
		if (is_safari) {
			slider.classList.add('safari');
		}

		$(slider).find('*').each(function() {
			$(this).attr('draggable', false);
		});

		slider.addEventListener('click', (e) => {
			// verhindere click wenn Maus über diesen Punkt hinaus bewegt wurde
			if (Math.abs(e.pageX - firstPosition) > threshold) {
				e.preventDefault();
				return; // Abbruch
			}

			// Klick navigiert, sofern das Element es nicht über den Punkt hinaus bewegt wurde
			if (this.clickable) {
				// 40% links vorheriges Bild zeigen - rechts nächstes Bild (vgl. mit baunetz.de)
				const width = $(slider).width();

				if (e.pageX - $(slider).offset().left < (width * 0.4)) {
					// prev
					this.slideTo('prev', e.target);
				} else {
					// next
					this.slideTo('next', e.target);
				}
			}
		});
		slider.addEventListener('mousedown', (e) => {
			isDown = true;
			slider.classList.add('-scrolling');
			$(slider).find('*').each(function() {
				$(this).attr('draggable', false);
			});
			startX = e.pageX - slider.offsetLeft;
			firstPosition = e.pageX;
			scrollLeft = slider.scrollLeft;
		});
		slider.addEventListener('mouseleave', () => {
			isDown = false;
			slider.classList.remove('-scrolling');
			$(slider).find('*').each(function() {
				$(this).removeAttr('draggable');
			});
		});
		slider.addEventListener('mouseup', () => {
			isDown = false;
			slider.classList.remove('-scrolling');
		});
		slider.addEventListener('mousemove', (e) => {
			if (!isDown) return;
			e.preventDefault();
			const x = e.pageX - slider.offsetLeft;
			const diffX = x - startX;
			slider.scrollLeft = scrollLeft - diffX;
		});

	}

	update() {
		if (this.previous) this.previous[this.hasPrevious ? 'removeAttribute' : 'setAttribute']('disabled', 'disabled');
		if (this.next) {
			// prepare for redirect - do not disable the target
			if (!this.hasNext && this.next.getAttribute('data-href')) {
				return;
			}
			this.next[this.hasNext ? 'removeAttribute' : 'setAttribute']('disabled', 'disabled');
		}
	}

	slideTo(position, target) {
		if (position === 'next' && !this.hasNext && target && target.getAttribute('data-href')) {
			// redirect to url instead of disabling the target (for homepage)
			window.location.href = target.getAttribute('data-href');
		}

		const futurePosition = position === 'next'
			? this.position + this.offset
			: this.position - this.offset;

		const edgeItem = Array.from(this.container.children).find(item => {
			// add 1px tolerance, because sometimes rounded values make trouble
			const itemPosition = item[`offset${this.positionName}`] + position === 'next' ? 1 : -1;
			const itemSize = item[`offset${this.sizeName}`];
			const edgePosition = position === 'next'
				? futurePosition
				: futurePosition + this.offset;
			return itemPosition < edgePosition && itemPosition + itemSize > edgePosition;
		});

		const destination = {top: 0, left: 0, behavior: 'smooth'};
		destination[this.positionName.toLowerCase()] = edgeItem
			? position === 'next'
				? edgeItem[`offset${this.positionName}`]
				: futurePosition + (edgeItem[`offset${this.sizeName}`] - (this.position - edgeItem[`offset${this.positionName}`]))
			: futurePosition;

		this.container.scrollTo(destination);
	}
}
