import $ from 'jquery';
import './isvisible.jquery';
import PartnerLog from './partner.log';

const ITEM_SELECTOR = '.image-parallax';

export class PartnerParallax {
	constructor() {
		this.$items = $(ITEM_SELECTOR);

		// show first of them
		const randomParallax = Math.round(Math.random() * (this.$items.length - 1));
		const $element = this.$items.eq(randomParallax);
		$element.removeAttr('hidden');
		const image = $element.attr('data-image');
		$element.css('background-image', 'url(' + image + ')');
		this.$items.not($element).remove();

		this.initEventlistener();
	}

	initEventlistener() {
		// track scroll for logger
		$(document).on('scroll.parallax', () => {
			this.logView();
		});

		// track click for logger
		const $el = $(ITEM_SELECTOR);
		$el.on('click', (e) => {
			e.preventDefault();

			// track request
			this.logClick();

			// delay link
			const $link = $(e.target);
			const url = $link.attr('href');
			const target = $link.attr('target');
			setTimeout(() => {
				if (target) {
					window.open(url, target);
				} else {
					window.location.href = url;
				}
			}, 500);
		});

		this.logView(); // is it visible the first time without scrolling
	}

	logView() {
		// log how often the parallax is visible -> track it
		const $el = $(ITEM_SELECTOR);

		// check visibility - if invisible do not track
		if (!$el.isVisible()) return;

		const herstellerId = $el.attr('data-hersteller-id');
		const heinzeAdito = $el.attr('data-heinze-adito');

		// valid data
		if (!(herstellerId && parseInt(herstellerId, 10) > 0)) return;

		// instant logger
		const logger = new PartnerLog();
		logger.log('superbanner_view', herstellerId, heinzeAdito, 1, 1);

		// unregister scroll listener
		$(document).off('scroll.parallax');
	}

	logClick() {
		// log click
		const $el = $(ITEM_SELECTOR);
		const herstellerId = $el.attr('data-hersteller-id');
		const heinzeAdito = $el.attr('data-heinze-adito');

		// valid data
		if (!(herstellerId && parseInt(herstellerId, 10) > 0)) return;

		// instant logger
		const logger = new PartnerLog();
		logger.log('superbanner_click', herstellerId, heinzeAdito, 1, 1);
	}

}
