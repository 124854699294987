import $ from 'jquery';
import {Overlay} from './index';
import {createSlide} from '../slider/index';

window.jQuery = $;

// todo limit einbauen
const isLoading = {};
const contentCache = {};
let activeOverlay;

// https://dmitripavlutin.com/timeout-fetch-request/
async function load(url, options = {}) {
	if (!url) return;

	try {
		// timeout max. 30s, kann erneut per Klick geladen werden
		const {timeout = 30 * 1000} = options;

		const controller = new AbortController();
		const id = setTimeout(() => controller.abort(), timeout);
		const response = await fetch(url, {
			...options,
			signal: controller.signal
		});
		clearTimeout(id);

		// on network error
		if (!response.ok) {
			throw new Error(response.status + ': ' + response.statusText);
		}

		const text = await response.text();

		// https://gomakethings.com/getting-html-with-fetch-in-vanilla-js/
		// DOMDocument
		const parser = new DOMParser();
		return parser.parseFromString(text, 'text/html');

	} catch (error) {
		console.log(url, error)
	}
}

/**
 * Öffnet ein Overlay, schließt alle anderen Instanzen (single Mode)
 * Glossar Overlay wird als HTML Content angegeben
 *
 * @param html
 * @param url
 */
function showOverlay(html, url) {
	// path im Hash eintragen (bei Direktaufruf Overlay laden)
	window.location.hash = 'path=' + url;

	const overlay = new Overlay({single: true});

	overlay.windowClass = 'glossar-overlay';

	const content = $(`<div>${html}</div>`);

	overlay.open($(content).html());

	// hash entfernen, wenn Overlay geschlossen wird
	$(overlay).on(overlay.EVENT_CLOSE, () => {
		// Hash leeren, Overlay schliessen bei hashchange durchführen
		window.location.hash = '';
	});

	// Inline Slider aktivieren
	const $inlineSlider = $('.' + overlay.windowClass).find('[data-slider]');
	if ($inlineSlider.length) {
		createSlide($inlineSlider.get(0));
	}

	activeOverlay = overlay;
}

/*
 * Glossar Overlay per URL Daten laden, wenn bereits geladen
 * aus dem Cache den Inhalt an show Methode weitergeben.
 */
function loadAndShowOverlay(url) {
	// Cache Content verwenden
	if (contentCache[url]) {
		showOverlay(contentCache[url], url);
		return;
	}

	// mehrfaches Laden unterbinden
	if (isLoading[url]) {
		return;
	}

	// lade Glossarbegriff Artikel
	(async () => {
		isLoading[url] = true;
		const result = await load(url);
		isLoading[url] = false;

		if (!result instanceof Document) return;

		const $article = $(result).find('.glossar-article');
		if (!$article.length) return;

		const content = $article.html();
		contentCache[url] = content;

		showOverlay(content, url);
	})();
}

/**
 * Hash path auslesen, wenn gesetzt Overlay laden per URL
 */
function loadByHash() {
	let pathHash = window.location.hash;
	if (!pathHash) return;

	let path = pathHash.substring(1);
	path = path.split('path=')
	if (path[1].startsWith('/glossar/')) {
		loadAndShowOverlay(path[1]);
	}
}

export function initGlossarOverlay() {
	// nicht auf der Glossar-Übersichtsseite
	if ($('.glossar-section').length) return;

	// Hash einlesen, öffne Overlay, per path
	loadByHash();

	// History change zurück/vor verwendendet

	$(window).on('hashchange', function () {
		// console.log('hashchange', location.hash);

		// wenn Hash leer ist, dann Overlay schliessen
		if (window.location.hash === '') {
			// close overlay
			if (activeOverlay) {
				activeOverlay.close();
				activeOverlay = null;
			}

			return;
		}

		// Hash einlesen, öffne Overlay
		loadByHash();
	});

	/*
	 * Klick auf /glossar Link öffnet und lädt Glossar-Overlay
	 *
	 */
	$(document).on('click', 'a[href^="/glossar/"]', (e) => {

		e.preventDefault();

		const url = e.target.getAttribute('href');

		loadAndShowOverlay(url);
	});
}
