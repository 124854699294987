import $ from 'jquery';
import {Overlay} from './index';

window.jQuery = $;

function showOverlay() {
	const seconds = 5;
	const $el = $('.product-contact-form');

	if (!$el.length) return;

	window.setTimeout(function () {
		const overlay = new Overlay();
		overlay.windowClass = 'product-contact-overlay';

		let content = `<div class="product-contact-form">${$el.html()}</div>`;
		content = $(`<div>${content}</div>`);

		// new ids for overlay to click label
		$(content).find('label').each(function() {
			let id = $(this).attr('for');
			$(content).find(`[id=${id}]`).attr('id', id + '_overlay');
			$(this).attr('for', id + '_overlay');
		});

		overlay.open($(content).html());
	}, seconds * 1000);

}

export function initProductContactOverlay() {
	// nur zeigen wenn es eine eid in der URL gibt, dann ist es ein Aufruf vom Newsletter aus
	if (typeof URLSearchParams !== 'undefined') {
		let params = new URLSearchParams(window.location.search);
		if (params.get('eID') || params.get('eid')) {
			showOverlay();
		}
	}
}
