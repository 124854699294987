import $ from 'jquery';
import {PARTNER_ITEM_SELECTOR, PARTNER_SLIDER_TIMEOUT, PARTNER_VISIBLE_ITEMS} from './partner.config';
import './isvisible.jquery';
import PartnerLog from './partner.log';

$.fn.shuffle = function () {
	let allElems = this.get(),
		getRandom = function (max) {
			return Math.floor(Math.random() * max);
		},
		shuffled = $.map(allElems, function () {
			let random = getRandom(allElems.length),
				randEl = $(allElems[random]).clone(true)[0];
			allElems.splice(random, 1);
			return randEl;
		});

	this.each(function (i) {
		$(this).replaceWith($(shuffled[i]));
	});

	return $(shuffled);

};

export class PartnerSlider {
	getTransitionEvent() {
		const transitions = {
			'transition': 'transitionend',
			'OTransition': 'oTransitionEnd',
			'MozTransition': 'transitionend',
			'WebkitTransition': 'webkitTransitionEnd',
		};

		const el = document.createElement('fakeelement');

		for (let t in transitions) {
			if (el.style[t] !== undefined) {
				return transitions[t];
			}
		}
	}

	get visibleItems() {
		const viewPort = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
		const mq = viewPort < 768 ? '' : '-md';

		const visibleItems = +$(this.sliderContainer).attr(`data-visible-items${mq}`);

		return visibleItems
			|| +$(this.sliderContainer).attr(`data-visible-items`)
			|| PARTNER_VISIBLE_ITEMS;
	}

	constructor(sliderContainer) {
		this.sliderContainer = sliderContainer;

		this.itemSelector = $(this.sliderContainer).attr('data-item-selector') || PARTNER_ITEM_SELECTOR;
		this.$items = $(this.sliderContainer).find(this.itemSelector);
		this.$items.find('img').removeAttr('hidden');

		const enableShuffle = $(this.sliderContainer).attr('data-disable-shuffle') !== 'true';
		const timeout = $(this.sliderContainer).attr('data-timeout') || PARTNER_SLIDER_TIMEOUT;


		[...this.$items].forEach((item) => {
			$(item).css({'width': `calc(100% / ${this.visibleItems}`, 'flex': `1 0 calc(100% / ${this.visibleItems}`});
		});

		if (enableShuffle) {
			this.$items.shuffle();	// shuffle items once
			this.$items = $(this.sliderContainer).find(this.itemSelector);
		}

		this.partnerLog = new PartnerLog();

		// first log after a short break
		window.setTimeout(() => {
			this.logView();
		}, 1000);

		// enable fading if there are more elements than visible
		if (this.$items.length > this.visibleItems) {
			this.transitionEvent = this.getTransitionEvent();

			// start interval with fading function
			window.setInterval(() => {
				this.fadeAndShift();
			}, timeout);
		}

		// track click on logo
		this.$items.on('click', (e) => {
			e.preventDefault();

			// track request
			this.logClick(e.currentTarget);

			// delay link
			const $link = $(e.currentTarget).find('a');
			const url = $link.attr('href');
			const target = $link.attr('target');
			setTimeout(() => {
				if (target) {
					window.open(url, target);
				} else {
					window.location.href = url;
				}
			}, 500);

		});
	}

	logView() {
		if (!this.partnerLog) return;

		// log how often the logo is visible -> track it, multiple logos in a row
		const $logos = $(this.sliderContainer).find(this.itemSelector);

		$logos.each((index,el) => {
			const $logo = $(el);
			if (!$logo.isVisible()) return;

			const herstellerId = $logo.attr('data-hersteller-id');
			const heinzeAdito = $logo.attr('data-heinze-adito');

			if (herstellerId && parseInt(herstellerId, 10) > 0) {
				// track every 50 visible rotations
				this.partnerLog.log('logo_view', herstellerId, heinzeAdito, 1, 50);
			}
		});
	}

	logClick($el) {
		// log click on logo
		const $logo = $($el);
		if (!$logo.length) return;

		const herstellerId = $logo.attr('data-hersteller-id');
		const heinzeAdito = $logo.attr('data-heinze-adito');

		if (!(herstellerId && parseInt(herstellerId, 10) > 0)) return;

		const logger = new PartnerLog();
		logger.log('logo_click', herstellerId, heinzeAdito, 1, 1);
	}

	/**
	 * fade and shift elements in partner slider
	 */
	fadeAndShift() {

		const fadeOutCallback = () => {
			this.sliderContainer.removeEventListener(this.transitionEvent, fadeOutCallback);

			// remove first n elements and append them
			const $shiftItems = $(this.sliderContainer)
				.find(`>:nth-child(-n + ${this.visibleItems})`)
				.remove();

			$(this.sliderContainer)
				.append($shiftItems)
				.css('opacity', 1);	// fadein all elements

			this.logView();
		};

		this.sliderContainer.addEventListener(this.transitionEvent, fadeOutCallback);

		// fadeout all elements
		$(this.sliderContainer).css('opacity', 0);

	}

}
