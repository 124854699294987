import $ from 'jquery';

window.jQuery = $;

const instances = new Set();

export class Overlay {
	constructor(options) {
		this.EVENT_OPEN = 'overlay.opened';
		this.EVENT_CLOSE = 'overlay.closed';

		this.overlay = null;
		this.options = options; // single: bool
		this._windowClass = '';

		if (this.options && this.options.single) {
			instances.add(this);
		}
	}

	get windowClass() {
		return this._windowClass;
	}

	set windowClass(value) {
		this._windowClass = typeof value === 'string' ? value : '';
	}

	content(content) {
		return `
			<section class="window ${this.windowClass}">
				<div class="window__inner">
					<header>
						<a href="#" class="window__close"></a>
					</header>
					<div class="window__body">
						<div class="window__content">
							${content}
						</div>
					</div>
				</div>
			</section>
		`;
	}

	addListener() {
		$(this.overlay).find('.window__close').on('click.overlay', (e) => {
			e.preventDefault();
			this.close();
		});

		// Escape schliesst Overlay
		$(document).on('keyup.overlay', (e) => {
			if (e.key == 'Escape' || e.keyCode == '27') {
				this.close();
			}
		});
	}

	removeListener() {
		$(this.overlay).find('.window__close').off('click.overlay');
		$(document).off('keyup.overlay');
	}

	open(content) {
		let tpl = this.content(content);

		this.overlay = $(tpl);

		// single Overlay Mode, lässt nur eine Instanz zu
		if (this.options && this.options.single) {
			if (instances.size > 0) {
				for (const instance of instances.values()) {
					// nicht das neue Overlay, alte entfernen
					if (this === instance) continue;
					// remove old instances
					instance.removeListener();
					$(instance.overlay).remove();
					instances.delete(instance);
				}
			}
		}

		// hinzufügen, ergänzen
		$('body').append(this.overlay);

		this.addListener();

		// trigger opened event
		$(this).trigger(this.EVENT_OPEN);
	}

	close() {
		this.removeListener();
		$(this.overlay).remove();

		// trigger closed event
		$(this).trigger(this.EVENT_CLOSE);

		if (this.options && this.options.single) {
			instances.delete(this);
		}
	}
}
