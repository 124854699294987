import $ from 'jquery';

function isProduction() {
	return window.location.hostname !== 'localhost';
}

// Heinze-Pixel neu schreiben
function HeinzeTracking() {
	if (!isProduction()) return;

    let $heinze = $('#heinze-datawarehouse');
    if ($heinze.length) {
        // eID, issue, context entfernen, da diese nur den 1. Aufruf zählen
    	let src = $heinze.attr('data-src');
		src = src.replace(/&eID=[^&]+/g, '');
		src = src.replace(/&context=[^&]+/g, '');
		src = src.replace(/&issue=[^&]+/g, '');

        src += '&random=' + new Date().getTime() + Math.floor(Math.random() * 100);
	    $heinze.attr('src', src);
    }
}

// IVW-Pixel absenden
function IVW_Tracking() {
	if (!isProduction()) return;
	// IOMM
	if (typeof IOMm !== 'undefined') {
		IOMm('configure', {st: 'baunetz', dn: 'data-9d5ca866eb.baunetz-id.de', mh:5});
		IOMm('pageview', { cp: 'CP/content', co: '' });
	}
}

// Webtrekk Pixel aktualisieren und neu senden
function updateWebtrekkGroup(text, param) {
	if (!isProduction()) return;

	if (typeof webtrekkV3 !== 'undefined' && typeof pageConfig !== 'undefined' && typeof wt !== 'undefined') {
		let oldWT = wt,
			oldPageConfig = pageConfig.contentId;

		pageConfig.contentId = text;

		let contentGroup1 = wt.contentGroup[1],
			contentGroup2 = wt.contentGroup[2],
			contentGroup3 = wt.contentGroup[3],
			contentGroup4 = wt.contentGroup[4];
		wt.contentId = pageConfig.contentId;
		wt.contentGroup = {
			1: contentGroup1,
			2: contentGroup2,
			3: contentGroup3,
			4: contentGroup4,
		};

		//CustomParameter
		param = param || false;
		wt.customParameter = param;

		// Statistik senden
		wt.sendinfo();

		// wieder zurücksetzen
		pageConfig.contentId = oldPageConfig;
		wt = oldWT;
	}
}

export function initTracking() {
	if (!isProduction()) return;

	// show gallery
	$(window).on('pswp-show', (e, data) => {
		HeinzeTracking();
		IVW_Tracking();

		if (typeof pageConfig === 'undefined') return;
		updateWebtrekkGroup(pageConfig.contentId + ' - Bildergalerie ' + data.index, {1: 'Bild ' + (data.currentIndex + 1)});
	});

	// next image on gallery
	$(window).on('pswp-change', (e, data) => {
		HeinzeTracking();
		IVW_Tracking();

		if (typeof pageConfig === 'undefined') return;
		updateWebtrekkGroup(pageConfig.contentId + ' - Bildergalerie ' + data.index, {1: 'Bild ' + (data.currentIndex + 1)});
	});
}
