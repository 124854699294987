import $ from 'jquery';

//vgl. https://github.com/morr/jquery.appear
//http://stackoverflow.com/questions/487073/check-if-element-is-visible-after-scrolling/19548486#19548486
//http://jsfiddle.net/RRSmQ/
$.fn.isVisible = function () {
	const elem = this;
	if (!$(elem).length) return false;

	// https://developer.mozilla.org/de/docs/Web/API/Page_Visibility_API
	// check if browser is visible
	let hidden = false;
	if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
		hidden = 'hidden';
	} else if (typeof document.msHidden !== 'undefined') {
		hidden = 'msHidden';
	} else if (typeof document.webkitHidden !== 'undefined') {
		hidden = 'webkitHidden';
	}

	if (hidden !== false) {
		// invisible browser, break
		if (document[hidden] === true) return false;
	}

	// invisible element (display: none, ...)
	if (!$(elem).is(':visible')) return false;

	// vertikale Sichtbarkeit
    const docViewTop = $(window).scrollTop();
    const docViewBottom = docViewTop + $(window).height();

    const elemTop = $(elem).offset().top;
    const elemBottom = elemTop + $(elem).height();

    const isVerticalVisible = (docViewBottom >= elemTop && docViewTop <= elemBottom);

	// horizontale Sichtbarkeit
	const docViewLeft = $(window).scrollLeft();
	const docViewRight = docViewLeft + $(window).width();

	const elemLeft = $(elem).offset().left;
	const elemRight = elemLeft + $(elem).width();

	const isHoricontalVisible = (docViewRight >= elemRight && docViewLeft <= elemRight);

    return isVerticalVisible && isHoricontalVisible;
};
