import $ from 'jquery';

window.jQuery = $;

export function initializeFilterDropdown() {
	const $form = $('form.filter-dropdowns');
	const $chosenSelect = $('[data-filter-dropdown]');

	$form.on('submit', function (e) {
		// remove empty values
		$(this).find('input,select').each(function () {
			if ($(this).val() == '')
				$(this).prop('disabled', true);
		});
		return true;
	});

	/*
	 * jquery Chosen Plugin
	 * https://harvesthq.github.io/chosen/
	 */
	if ($chosenSelect.length > 0) {
		const chosenLoader = import('chosen-js');

		chosenLoader.then(() => {
			$chosenSelect.chosen({
				allow_single_deselect: true,
				no_results_text: 'Kein Treffer',
				width: '100%',
				disable_search_threshold: 20 // mind. 20 Einträge, dann Suchfeld einblenden
			}).change(function () {
				$chosenSelect.each(function () {
					const $select = $(this);
					if ($select.val() === '') {
						$select.remove();
					}
				});

				// send form, trigger reload with new data
				$form.submit();
			});

			// set placeholder for search
			$chosenSelect.next().find('.chosen-search-input').attr('placeholder', 'Type in something');

			// if not supported (mobile)
			if (!$chosenSelect.data('chosen')) {
				$chosenSelect.each(function() {
					$(this).addClass('chosen-unsupported');
					$(this).children('option').first().text($(this).attr('data-placeholder'));
				});
			}

			// add item count
			/*
						$chosenSelect.each(function () {
							const $select = $(this);
							const count = $select.data('count');
							if (count) {
								$select.next().find('a.chosen-single').append(`<i class="filter-dropdown-count">${count}</i>`);
							}
						});
			*/
		});
	}

	/*
	 * Formular beim Klick auf eine Produktkategorie absenden,
	 * vermischt die Felder mit den Dropdown-Filtern
	 */
	const $produktkategorie = $('.filter-category a');
	if ($produktkategorie.length) {
		$produktkategorie.on('click', function(e) {
			e.preventDefault();
			$form.find('input[name=produktkategorie]').val($(this).attr('data-produktkategorie'));
			$form.submit();
		});
	}
}
