import $ from 'jquery';

export function handleProductContactForm() {
	$(document).on('submit', function (e) {
		// Nur Produktkontaktanfrage Formular
		if (!$(e.target).parent().hasClass('product-contact-form')) return;

		e.preventDefault();
		const $form = $(e.target);
		const data = $form.serialize();

		// Grund der Anfrage - Checkboxengruppe ist Pflichtfeld, mind. eines auswählen
		if ($("input[type=checkbox][name='grund[]']:checked").length < 1) {
			alert('Wählen Sie bitte den Grund der Anfrage.');
			return false;
		}

		const url = `/heinze/productcontact`;
		$.ajax({
			type: 'POST',
			url,
			data,
			dataType: 'json'
		}).done(function (response) {
			if (response && response['success'] === true) {
				// alert("Ihre Kontaktanfrage wurde erfolgreich versendet.");
				$form.html(`<p>Ihre Anfrage wurde erfolgreich versendet.</p>`);
			} else {
				alert("Es ist ein Problem beim Versenden der Kontaktanfrage aufgetreten.");
			}
		}).fail(function (response) {
			alert("Es ist ein Problem beim Versenden der Kontaktanfrage aufgetreten.");
		});

		return false;
	});
}
