import $ from 'jquery';
/*
 * Logs how often the partner logo slider or partner parallax are visible,
 * track
 */

export default class PartnerLog {
	constructor() {
		this.reset();
		this.initEventlistener();
	}

	initEventlistener() {

		// visibility
		document.addEventListener("visibilitychange", () => {
			// if hidden track it
			if (document.hidden || document.visibilityState === 'hidden') {
				this.track();
			}
		}, false);

		// reload, close, next, prev will track
		window.addEventListener('pagehide', (e) => {
			this.track();
		}, false);
	}

	reset() {
		this.hersteller = {};
		this.sum = 0;
	}

	/**
	 * logos + super banner (parallax)
	 * @param logType string (banner or logo)
	 * @param herstellerId int
	 * @param heinzeAdito int
	 * @param counter int
	 * @param trackInterval int
	 */
	log(logType, herstellerId, heinzeAdito, counter, trackInterval) {
		if (!this.hersteller[herstellerId]) {
			this.hersteller[herstellerId] = {
				hersteller_id: herstellerId,
			};
			this.hersteller[herstellerId][logType] = 0;
		}

		this.hersteller[herstellerId][logType] += parseInt(counter, 10) > 0 ? counter : 1;
		this.sum += counter > 0 ? counter : 1; // count all counters together, for trackInterval

		if (heinzeAdito) {
			this.hersteller[herstellerId].heinze_adito = heinzeAdito;
		}

		if (this.sum % trackInterval === 0) {
			this.track();
		}
	}

	track() {
		// nur live tracken
		if (window.location.host.indexOf('baunetz-id.de') === -1) return;

		if (!this.hersteller) return;

		// transform to array objects
		const input = Object.keys(this.hersteller).map(herstellerId => {
			return this.hersteller[herstellerId];
		});

		// empty data break
		if (!input.length) return;

		const url = 'https://www.baunetz-id.de/report/partnerlog';
		$.post(url, {data: input});

		this.reset(); // alle Daten neu ermitteln
	}
}
