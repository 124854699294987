import $ from 'jquery';
import {Slider} from './slider';
import {hideNativeScrollbars} from './hide-native-scrollbars';
// import './slider.css';

export function createSlide(container) {
	hideNativeScrollbars(container);
	const sliderName = container.dataset.slider;
	const previous = document.querySelector(`[data-slider-previous="${sliderName}"]`);
	const next = document.querySelector(`[data-slider-next="${sliderName}"]`);
	// per Klick weiternavigieren statt nur per Drag 40% Regel wie bei Photoswipe
	let clickable = document.querySelector(`[data-slider-clickable="${sliderName}"]`);
	clickable = !!clickable;
	new Slider(container, {previous, next, clickable});
}

export function initializeSlider() {
	$('[data-slider]').each(function() {
		createSlide(this)
		// let container = this;
		// hideNativeScrollbars(container);
		// const sliderName = container.dataset.slider;
		// const previous = document.querySelector(`[data-slider-previous="${sliderName}"]`);
		// const next = document.querySelector(`[data-slider-next="${sliderName}"]`);
		// // per Klick weiternavigieren statt nur per Drag 40% Regel wie bei Photoswipe
		// let clickable = document.querySelector(`[data-slider-clickable="${sliderName}"]`);
		// clickable = !!clickable;
		// new Slider(container, {previous, next, clickable});
	});

	// shuffle slider elements
	// https://stackoverflow.com/a/11972692/270935
	const elements = document.querySelector('[data-slider-random="true"]');
	if (elements) {
		// not blocking
		setTimeout(() => {
			for (let i = elements.children.length; i >= 0; i--) {
					elements.appendChild(elements.children[Math.random() * i | 0]);
			}
		}, 10);
	}
}
