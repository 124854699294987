import $ from 'jquery';
import {Overlay} from './index';

window.jQuery = $;

const NewsletterAnmeldungPromotion = 'baunetz_id.Newsletter.Anmeldung.Promotion'; //Speichert ob der Benutzer mind. 1 mal die Newsletteranmeldung gesehen hat
const NewsletterAnmeldungPromotionTimer = 'baunetz_id.Newsletter.Anmeldung.Zeit'; // Datum festhalten

function handlePromotion() {
	let output = false;
	if (!window.localStorage) return output;

	// Prüfe ob es bereits die Newsletter-Seite ist
	if ($('.newsletter-h1').length) return;

	// URL-Parameter context vorhanden, dann nicht anzeigen (aus NL aufgerufen)
	if (window.location.search.indexOf('context=') >= 0) return;

	try {
		// Zeitlimit prüfen
		let wasPromoted = localStorage.getItem(NewsletterAnmeldungPromotion);
		wasPromoted = Boolean(wasPromoted === 'true' && wasPromoted !== 'false' && wasPromoted);
		let storedDate = localStorage.getItem(NewsletterAnmeldungPromotionTimer);

		storedDate = storedDate ? new Date(storedDate) : false;
		let timeOverlapsed;

		let currentDate = new Date();

		// erstes Mal das Datum speichern
		if (storedDate instanceof Date) {
			// alle 2 Wochen
			let diffTime = 2 * (7 * 24 * 60 * 60 * 1000);
			timeOverlapsed = (currentDate.getTime() - storedDate.getTime() >= diffTime);
		} else {
			timeOverlapsed = true;
		}

		output = !wasPromoted || timeOverlapsed;

	} catch (error) {

	}

	return output;
}

function showOverlay() {
	const seconds = 10;

	window.setTimeout(function () {

		// Zeit festhalten im LocalStorage
		try {
			localStorage.setItem(NewsletterAnmeldungPromotion, 'true');
			// setze neues Zeitfenster
			const currentDate = new Date();
			localStorage.setItem(NewsletterAnmeldungPromotionTimer, currentDate.toString());
		} catch (error) {

		}

		const newsletterOverlay = new Overlay();
		newsletterOverlay.windowClass = 'newsletter-overlay';
		newsletterOverlay.open(`
			<div class="newsletter-teaser"><span>Nichts mehr verpassen!</span> Jede Woche neue Inspirationen aus Design und Innenarchitektur:</div>
			<iframe src="https://cdn2.baunetz-id.de/newsletter/subscribe" frameborder="0" width="100%" height="500"></iframe>
		`);

	}, seconds * 1000);

}

export function initNewsletterOverlay() {
	// OptinMonster stört das NL-Overlay, auf Wunsch vorübergehend NL abschalten
	return;

	let promotionReady = handlePromotion();
	if (!promotionReady) return;

	showOverlay();
}
