import PhotoSwipe from 'photoswipe/dist/photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';
import $ from 'jquery';

let _selector_gallery = '.pswp',
	_selector_title = '.article-headline-pswp',
	_selector_caption = 'figcaption',
	_selector_link = '.pswp-link',
	_cache = [],
	_gallery = false, // PhotoSwipe Galerie-Instanz
	_just_opened = false,
	_initialized = false;

export default new class PhotoSwipeController {
	/**
	 * Galerie Items initialisieren
	 * galleryList Arrays aus
	 * {src: 'https://placekitten.com/640/640', w: 640, h: 640, title: 'Katze'}
	 */
	init() {
		if (!window.galleryList || !Array.isArray(window.galleryList)) return;

		_cache = window.galleryList;
		_initialized = true;
	};

	/**
	 * Controller Variablen zurücksetzen
	 */
	destroy() {

		if (_initialized) {
			_gallery = false;
			_cache = [];
			$(_selector_gallery).first().off('click.pswp');
		}

		_initialized = false;
	}

	/**
	 * URL in Galerien suchen und Galerieliste + Index, Id zurückgeben
	 * @param url
	 * @returns {{images: [], index: null, id: null}}
	 */
	findGalleryOfImage(url) {
		let gallery = {
			id: null,
			index: null,
			images: []
		};

		_cache.some((images, galleryId) => {
			return images.some((image, index) => {
				if (image.src === url) {
					gallery = {
						id: galleryId,
						index: index,
						images: images
					};
					return true;
				}
			});
		});

		return gallery;
	}

	/**
	 * Galerie öffnen
	 *
	 * @param HTMLImageElement image
	 */
	show(imgEl) {
		if (!(imgEl.tagName && imgEl.tagName === 'IMG')) return;

		let gallery = this.findGalleryOfImage(imgEl.getAttribute('src'));
		if (!gallery.images || !gallery.images.length) return;

		const galleryId = parseInt(gallery.id, 10) + 1;
		const index = parseInt(gallery.index, 10);

		const pswp = $(_selector_gallery).first();

		//Vgl. mit http://photoswipe.com/documentation/options.html
		const options = {
			history: false,

			closeEl: true,
			captionEl: true,
			fullscreenEl: true,
			zoomEl: false, //Zoom deaktiviert (stattdessen 1/3, 2/3 Klickstrecken)
			shareEl: false,
			counterEl: true,
			arrowEl: true,
			preloaderEl: false,

			barsSize: {top: 44, bottom: 130},

			maxSpreadZoom: 1,
			bgOpacity: 0.95,
			galleryUID: galleryId,
			closeElClasses: ['caption', 'ui'], //Schliessen nur bestimmten Flächen gestatten (https://github.com/dimsemenov/PhotoSwipe/issues/762)

			/**
			 * Zoomanimation vom Thumbnail
			 * @param index
			 * @returns {{w: number, x: number, y: number}}
			 */
			getThumbBoundsFn: function (index) {
				if (!imgEl) return {x: 0, y: 0, w: 0};

				const thumbnail = imgEl;

				const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
				const rect = thumbnail.getBoundingClientRect();
				const offset = -1 * parseInt($(_selector_gallery).css('top'), 10);

				return {x: rect.left, y: rect.top + pageYScroll + offset, w: rect.width};
			},

			/**
			 * Anzahl der Bilder in der Galerie
			 * @returns {number}
			 */
			getNumItemsFn: function () {
				return gallery.images.length;
			},

			index: index,
		};

		_just_opened = true;

		// initialize photoswipe gallery
		_gallery = new PhotoSwipe(pswp.get(0), PhotoSwipeUI_Default, gallery.images, options);

		(function () {
			// Artikeltitel oben anzeigen
			const title = $('main header h1:first').text();
			const subTitle = $('main header h2:first').text();
			// const foto = $('[data-foto]').text().trim();
			const $titleContainer = $(_selector_title);
			$titleContainer.find('.title').text(title);
			$titleContainer.find('.subtitle').text(subTitle);
			// if (foto !== '') {
			// 	$titleContainer.find('.foto').text('Fotograf: ' + foto);
			// }
		})();

		/*
		 * Click auf linke oder rechte Hälfte NEBEN dem Bild ist wie ein Klick auf den next- prev Button
		 */
		$(pswp.get(0)).off('click.pswp');
		$(pswp.get(0)).on('click.pswp', function (e) {
			//nicht 2mal weiternavigieren beim Button
			if ($(e.target).is('button')) {
				return;
			}

			let width = $('body').width();

			//40% links vorheriges Bild zeigen - rechts nächstes Bild (vgl. mit baunetz.de)
			if (e.pageX < (width * 0.4)) {
				_gallery.prev();
			} else {
				_gallery.next();
			}
		});

		_gallery.listen('close', function () {
			$(window).trigger('pswp-close');
		});

		_gallery.listen('afterChange', function () {
			if (!_just_opened) {
				$(window).trigger('pswp-change', {
					index: galleryId, gallery: _gallery, currentIndex: _gallery.getCurrentIndex()
				});
			} else {
				_just_opened = false;
			}

		});

		_gallery.init();
		$(window).trigger('pswp-show', {
			index: galleryId, gallery: _gallery, currentIndex: _gallery.getCurrentIndex()
		});
	}

	/**
	 * Schliessen der Galerie
	 */
	close() {

		if (_gallery) {
			_gallery.close();
			_gallery = false;
		}
	}

	/**
	 * Ist die Galerie aktiv?
	 *
	 * @returns {boolean}
	 */
	isOpen() {
		return !!_gallery;
	}

};
