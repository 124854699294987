/*
 * Heinze PageDot Service
 */

import $ from 'jquery';

class PagedotService {
    constructor() {
        this.controller = 'baunetz-id';
        this.action = '';
        this.pageObjects = [];

        this.initEventListener();
    }

	trackEvent(key, value) {
		if (typeof key !== 'string' || typeof value !== 'string') return;

		if (typeof window.navigator === 'undefined') return;
		if (typeof window.navigator.sendBeacon === 'undefined') return;

		// hash crypt https notwendig
		if (typeof window.crypto === 'undefined') return;
		if (typeof window.crypto.subtle === 'undefined') return;

		const DWHPageDotService = {
			currentPIID: function () {
				const img = document.querySelector('img.jsPageDot');
				return this.parsePIID(img);
			},
			parsePIID: function (img) {
				if (!img || !img.src) return '';
				try {
					const u = new URL(img.src);
					return u.searchParams.get('piid');
				} catch (e) {

				}
				return '';
			},
		};

		// send event
		// parameter: piid, r, hv (piid + r + po)
		const r = (Math.floor(Math.random() * 10000) + 1);
		const po = JSON.stringify([{key: key, value: value}]);
		const piid = DWHPageDotService.currentPIID();

		// https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
		async function hash(message) {
			const msgUint8 = new TextEncoder().encode(message);
			const hashBuffer = await window.crypto.subtle.digest("SHA-256", msgUint8);
			const hashArray = Array.from(new Uint8Array(hashBuffer));
			return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
		}

		// console.log('event', piid, po);
		if (typeof piid === 'string' && piid !== '') {
			hash(piid + r + po).then(function (hv) {
				// console.log('hash', piid, r, hv, typeof hv)
				if (typeof hv !== 'string') return;

				const urlParams = new URLSearchParams();
				urlParams.set('piid', piid);
				urlParams.set('r', r.toString());
				urlParams.set('hv', hv);
				urlParams.set('po', po);

				// use beacon instead of fetch
				navigator.sendBeacon('/dwh-event', urlParams);
			});
		}
	}

	handleBaunetzSuperheader() {
		const me = this;
		const baunetzSuperheader = document.getElementById('baunetz-superheader');
		if (!baunetzSuperheader) return;

		// open portal list
		window.addEventListener('baunetzsuperheader-open-portal', () => {
			this.trackEvent('superHeaderPortalList', 'open');
		});

		// portal button clicked
		$(document).on('click', '#baunetz-superheader .portal-button', function () {
			const title = $(this).attr('data-title');
			me.trackEvent('superHeaderPortalClick', title);
		});

		// button clicked
		$(document).on('click', '#baunetz-superheader .bn-button-container a.bn-button', function () {
			const title = $(this).attr('data-title');
			me.trackEvent('superHeaderButtonClick', title);
		});
	}

    initEventListener() {

		this.handleBaunetzSuperheader();

    	/*
    	 * Bildergalerie Index und Bildnummer tracken
    	 */
    	const trackGallery = (data) => {
    		this.buildContentGroups();

    		this.pageObjects.push({key: 'gallery', value: 'gallery-' + data.index});
			this.pageObjects.push({key: 'galleryImage', value: data.currentIndex + 1});

			this.send();
		}

		const trackLanguageSwitch = (data) => {
    		if (!data || typeof data.language === "undefined") return;

			this.trackEvent('translate', data.language);
		}

		// show gallery
		$(window).on('pswp-show', (e, data) => {
			trackGallery(data);
		});

		// next image on gallery
		$(window).on('pswp-change', (e, data) => {
			trackGallery(data);
		});

		// Conword translate
		$(window).on('translate', (e, data) => {
			trackLanguageSwitch(data);
		});
    }

	/**
	 * Heinze img URL parsen und Parameter wiederverwenden
	 */
    parseDot() {
		if (!('URL' in window)) return;

    	// Daten übernehmen
		const pageDotElement = $('#heinze-pagedot');
		let data = pageDotElement.attr('data-src');

		if (!data) return;

		try {
			let url = new URL(data);
			this.controller = url.searchParams.get('controllername');
			this.action = url.searchParams.get('actionname');
			let jsonString = url.searchParams.get('pageobjects');
			this.pageObjects = JSON.parse(jsonString);
		} catch (e) {

		}
	}

    /**
     * Sendeprozess vorbereiten
     */
    process() {
        this.buildContentGroups();
        this.send();
    }

    buildContentGroups() {
        this.pageObjects = [];

        this.parseDot();
    }

    getUrlParam(key) {
        if (!('URLSearchParams' in window)) return '';

        let urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(key);
    }

    getSessionParam(key) {
        if (!('sessionStorage' in window)) return '';

        let output = '';
        try {
            output = sessionStorage.getItem(key);
        } catch (e) {

        }

        return output;
    }

    setSessionParam(key, value) {
        if (!('sessionStorage' in window)) return '';

        try {
            sessionStorage.setItem(key, value);
        } catch (e) {

        }
    }

    /**
     * URL-Parameter context, in sessionStorage schreiben,
     * soll mitgegeben werden bei einem Visit
     * @returns {string}
     */
    getContext() {
        let context = '';
        let urlContext = this.getUrlParam('context');
        let sessionContext = this.getSessionParam('context');
        // sessionContext erneuern wenn URL context vorhanden ist
        if (urlContext && urlContext !== '') {
            this.setSessionParam('context', urlContext);
            context = urlContext;
        } else {
            context = sessionContext;
        }

        return context && context !== 'null' ? context : '';
    }

    generateSessionId() {
        // https://gist.github.com/gordonbrander/2230317
        return (Math.random().toString(36).substr(2, 9) + Date.now().toString(32) + Math.random().toString(36).substr(2, 9)).substr(1, 27);
    }

    getSessionId() {
        // sessionId auslesen
        let sessionId = this.getSessionParam('sessionId');
        if (sessionId && sessionId !== '') return sessionId;

        // generiere neue session Id
        sessionId = this.generateSessionId();
        this.setSessionParam('sessionId', sessionId);
        return sessionId;
    }

    send() {
        console.group('service.pagedot');
        console.log('CONTROLLER  ', this.controller);
        console.log('ACTION      ', this.action);
        console.log('PAGEOBJECTS ', this.pageObjects);
        console.groupEnd();

		this.controller = this.controller !== '' ? this.controller : 'baunetz-id';
		let url = '/dwh-punkt?c=' + this.controller + '&a=' + this.action + '&po=' + JSON.stringify(this.pageObjects) + '&r=' + (Math.floor(Math.random() * 10000) + 1);

		let bnidPunkt = document.getElementById('bnid-punkt');
		if (!bnidPunkt) {
			document.body.insertAdjacentHTML('beforeend', '<img id="bnid-punkt" alt="" src="' + url + '" style="position: fixed; top: 101vh; left: 101vw; height: 1px; width: 1px;">');
			bnidPunkt = document.getElementById('bnid-punkt');
		}
		if (bnidPunkt) {
			bnidPunkt.setAttribute('src', url);
		}

/*
        let context = this.getContext();
        let referer = 'referrer' in document ? encodeURIComponent(document.referrer) : '';
        let sessionId = encodeURIComponent(this.getSessionId());
        let sitename = typeof window.location.pathname !== 'undefined' ? encodeURIComponent(window.location.pathname) : '';
        this.controller = this.controller !== '' ? this.controller : 'baunetz-id';
        let url = 'https://www.baunetz.de/dwhpagedots/baunetz-id/dot.php?controller=' + this.controller + '&action=' + this.action + '&po=' + JSON.stringify(this.pageObjects) + '&autoConfirmed=1&sitename=' + sitename + '&sessionId=' + sessionId + '&referer=' + referer + '&context=' + context + '&r=' + (Math.floor(Math.random() * 10000) + 1);
        let punkt = document.getElementById('bnid-punkt');
        if (punkt) {
            punkt.setAttribute('src', url);
        }
*/
    }
}

function isProduction() {
	return window.location.hostname !== 'localhost';
}

export function initPageDotTracking() {
	if (isProduction()) {
		const pageDot = new PagedotService();
		// pageDot.process();

		window.pageDot = pageDot;
	}
}
