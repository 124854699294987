import PhotoSwipeController from './gallery';
import $ from 'jquery';

export function initializeGallery() {

	PhotoSwipeController.init();

	/**
	 * Bildergalerie anzeigen, Bild-URL sucht die passende Galerie heraus,
	 * diese steht in window.galleryList
	 */
	$(document).on('click', 'figure.image img', function(e) {
		// keine Inline Galerie mit Vollbild
		if ($(this).closest('.inline-slider').length) return;

		PhotoSwipeController.show(e.target);
	});

}
