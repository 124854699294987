import $ from 'jquery';
import {PartnerSlider} from './partner';
import {PartnerParallax} from './partner-parallax';
import {PARTNERS_SELECTOR} from './partner.config';

export function initializePartners(partnersSelector = PARTNERS_SELECTOR) {
	$(partnersSelector).each(function () {
		new PartnerSlider(this);
	});

	// init Partner parallax
	new PartnerParallax();
}
