/**
 * Wrap the scrolling area with an element to hide the scrollbars
 *
 * @param {HTMLElement} scrollingArea
 */
export function hideNativeScrollbars(scrollingArea) {
	const nativeBarSizeX = scrollingArea.offsetHeight - scrollingArea.clientHeight;
	const nativeBarSizeY = scrollingArea.offsetWidth - scrollingArea.clientWidth;

	// only wrap if necessary, scrollbars may have been hidden by CSS
	if (nativeBarSizeX > 0 || nativeBarSizeY > 0) {
		// wrap the scrolling area
		const wrapper = document.createElement('div');
		scrollingArea.parentNode.insertBefore(wrapper, scrollingArea);
		wrapper.appendChild(scrollingArea);

		// move scrollbar outside the wrapper
		/*Object.assign(scrollingArea.style, {
			margin: `0 -${nativeBarSizeY}px -${nativeBarSizeX}px 0`,
		});*/
		scrollingArea.style.margin = `0 -${nativeBarSizeY}px -${nativeBarSizeX}px 0`;

		// hide things outside the wrapper
		wrapper.style.overflow = 'hidden';
	}
}
