import $ from 'jquery';

export function initMisc() {
	// target for external links
	$('a[href*="//"]:not([target="_blank"]):not([href*="baunetz-id.de"])').attr('rel', 'noopener').attr('target', '_blank');

	const glossar = () => {
		const $glossarArticle = $('.glossar-article');
		if (!$glossarArticle.length) return;

		const toggle = () => {
			// Detail ausblenden, A-Z einblenden
			$glossarArticle.attr('hidden', 'hidden');
			$('.glossar-letters').removeAttr('hidden').addClass('is-visible');
		}

		const $close = $glossarArticle.find('.close');

		// A-Z angeklickt auf Detailseite
		$('.glossar-az-list').on('click', function () {
			toggle();
		});

		// Detailseite schliessen geklickt
		$close.on('click', function (e) {
			e.preventDefault();
			toggle();
		});
	};
	glossar();

	/*
		const $shareLink = $('.navigator-share');

		// mobile share
		if (navigator.share && navigator.canShare && navigator.canShare()) {

			$shareLink.removeAttr('hidden');

			$shareLink.on('click', function () {
				const title = document.title;
				const url = document.querySelector('link[rel=canonical]') ? document.querySelector('link[rel=canonical]').href : document.location.href;

				navigator.share({
					title: title,
					url: url,
				}).then(() => {
					// shared
				}).catch(err => {
					console.error(err)
				});
			});

		} else {
			$shareLink.remove();
		}
	*/


	/*
	 * Conword Sprachwechsler
	 */
	const initLangSwitch = () => {
		const langSwitch = document.querySelectorAll('.bnid-language-switch > a');
		if (!langSwitch.length) return;

		// aktive Sprache anzeigen
		const currentLang = Conword.get_current_language();
		langSwitch.forEach((element) => {
			if (element.dataset.lang !== currentLang) return;
			element.classList.add('active');
		});

		langSwitch.forEach((item) => {
			item.addEventListener('click', (e) => {
				e.preventDefault();
				const tag = e.target;
				const lang = e.target.dataset.lang;
				if (lang === Conword.get_current_language()) return;
				// translate
				Conword.translate(lang);

				// track language switch in pagedot
				$(window).trigger('translate', {
					language: lang,
				});

				// reset active
				langSwitch.forEach((element) => element.classList.remove('active'));
				// active class
				tag.classList.add('active');
			});
		});
	};

	if (typeof Conword === 'undefined') {
		let intervalConword = 0;
		let timerConword = setInterval(() => {
			if (typeof Conword !== 'undefined') {
				clearInterval(timerConword);
				initLangSwitch();
			}
			intervalConword += 1;
			// nach 2.5s Interval abbrechen
			if (intervalConword > 10) {
				// remove interval, Conword ist nicht vorhanden
				clearInterval(timerConword);
				const el = document.querySelector('.bnid-language-switch');
				if (el) {
					el.remove(); // entferne Sprachwechsel, wenn nicht aktiv
				}
			}
		}, 250);
	} else {
		initLangSwitch();
	}
}
